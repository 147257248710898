import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import HeroDigitalMarketing from "../../components/ServiceSinglePage/Hero/DigitalMarketing/HeroDigitalMarketing"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import ServiceCTA from "../../components/ServiceLanding/CTA/ServiceCTA"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import HeroUIUX from "../../components/ServiceSinglePage/Hero/UIUX/HeroUIUX"
import HeroSeo from "../../components/ServiceSinglePage/Hero/SEO/HeroSeo"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"

function UIUXPage({ location }) {
    const heroTextTitle = "SEO Services"
    const heroTextSmallText =
        "Transform your online presence with Saigon Digital, where advanced SEO meets compelling content to skyrocket your brand to the top of search rankings, driving organic traffic and establishing a standout digital footprint."
    const ctaTitle = "Amplify Your Reach, Boost Your Visibility"
    const heroData = [
        "From keyword optimisation to content strategy and link building, we use industry-leading techniques to help your business thrive in the digital landscape. With our bespoke SEO strategies, you can expect tangible results, including increased website traffic, higher search rankings, and improved conversion rate. ",
        "With Saigon Digital, you'll partner for a tailored approach designed to incrementally boost your visibility and audience engagement, grounding your brand's digital journey in solid results."
    ]

    const strategyIcons = [
        {
            name: "computer-eye",
            title: "Visibility",
            text: "Higher search rankings mean more visibility. It's simple – the higher you rank, the more likely people will see you."
        },
        {
            name: "traffic",
            title: "User Research",
            text: "With greater visibility comes more traffic to your website. And not just any traffic, but quality traffic from people genuinely interested in what you offer."
        },
        {
            name: "badge",
            title: "Ideation",
            text: "Ranking high in search results also boosts your business's credibility. People trust search engines, so a top spot signals you're a reliable choice."
        }
    ]

    const designIcons = [
        {
            name: "keyword",
            title: "Keyword Research",
            text: "We kick things off by identifying the words and phrases your potential customers are using to find services or products like yours. It's all about getting into the minds of your audience."
        },
        {
            name: "optimize",
            title: "On-Page Optimisation",
            text: "We then fine-tune your website's content and structure to ensure those keywords are appropriately integrated. From titles and meta descriptions to headings and internal links, we cover all bases to make your site as search-engine-friendly as possible."
        },
        {
            name: "content-creator",
            title: "Content Creation",
            text: "Great content is the backbone of effective SEO. We help you create compelling, valuable content that resonates with your audience and search engines alike."
        },
        {
            name: "loop",
            title: "Technical SEO",
            text: "This behind-the-scenes work ensures your website is built in a way that search engines love. We look at site speed, mobile-friendliness, and secure connections to keep your site in tip-top shape."
        },
        {
            name: "link",
            title: "Link Building",
            text: "High-quality backlinks from reputable sites tell search engines that your site is credible and authoritative. We help you earn these valuable links through strategic outreach and content marketing."
        },
        {
            name: "computer-location",
            title: "Local SEO",
            text: "If your business serves local customers, our local SEO strategies make sure you're found by people in your area. From Google My Business optimisation to local keywords, we've got you covered."
        }
    ]

    const context = {
        pageName: "Service | SEO Service",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroSeo
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <div className="[&_.service-icon]:pb-0 lg:[&_.service-icon]:pt-40 [&_.wave-bottom]:hidden">
                <ServiceIconBlock
                    title={"Why SEO Matters"}
                    icons={strategyIcons}
                />
            </div>
            <div className="[&_.service-icon]:py-[120px] [&_.wave-bottom]:hidden [&_.wave-top]:hidden">
                <ServiceIconBlock
                    title={"Our SEO Services"}
                    icons={designIcons}
                />
            </div>
            <div className="mt-36">
                <ServiceCTA title={ctaTitle} />
            </div>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.seoFaqs} />
            </div>
            <LatestBlogs/>
        </Layout>
    )
}

export default UIUXPage
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why should I choose Saigon Digital for Search Engine Optimisation (SEO) services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Saigon Digital's SEO services stand out due to our data-driven approach, proven track record, and dedication to achieving tangible results. As a leading SEO agency, we tailor our strategies to your specific needs, ensuring that your website performs at its best."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is Search Engine Optimisation (SEO)?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Search Engine Optimisation (SEO) is the process of enhancing your website to improve its visibility on search engines like Google. By optimising various elements of your site, SEO helps your website rank higher in search engine results, driving more organic traffic."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Why is SEO important for my website?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "SEO is crucial because it increases your website’s visibility in search engine results, leading to more organic traffic and potential customers. Investing in SEO services ensures that your site is easily found by users searching for the products or services you offer."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What services do you offer as a full-service SEO agency?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "As a full-service SEO agency, Saigon Digital offer a wide range of SEO services, including keyword research, on-page optimisation, technical SEO, link building, and content optimisation. Our SEO web services are designed to cover all aspects of your site’s performance in search engines."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How long does it take to see results from SEO?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The timeline for seeing results from SEO varies, but generally, you can expect to see improvements within a few months. Our full-service SEO approach ensures that we consistently work on your site to achieve and maintain high rankings."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How much do SEO services cost?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The cost of SEO services varies based on the scope of work and your business needs. However, our SEO agency offers competitive pricing for full-service SEO, ensuring you receive excellent value for your investment."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What kind of support can I expect during an SEO campaign?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "During an SEO campaign, you can expect continuous support from our team, including regular updates, strategic adjustments, and performance reviews. Our full-service SEO approach ensures that your campaign is always on track to achieve the best results."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title="SEO Services | Saigon Digital"
            description="Boost your online visibility with Saigon Digital's SEO strategies that enhance rankings and drive quality traffic."
        />
    </>
)
